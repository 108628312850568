var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "margin-bottom", staticStyle: { padding: "15px 15px" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", {
            staticStyle: { padding: "15px 15px" },
            attrs: { sm: 24 }
          })
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ]
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("user.userInfo")))])]
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "formData",
                      attrs: {
                        model: _vm.formData,
                        rules: _vm.formRules,
                        "label-width": "120px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "playerId",
                            label: _vm.$t("user.playerId")
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("user.inputPlayerId")
                            },
                            on: { blur: _vm.queryPlayerInfo },
                            model: {
                              value: _vm.formData.playerId,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "playerId", $$v)
                              },
                              expression: "formData.playerId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.nickname") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.nickname,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "nickname", $$v)
                                  },
                                  expression: "playerInfo.nickname"
                                }
                              }),
                              _c("el-input", {
                                staticClass: "new-input",
                                attrs: {
                                  type: "text",
                                  controls: false,
                                  placeholder: _vm.$t("user.inputModifyName")
                                },
                                model: {
                                  value: _vm.formData.modifyName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "modifyName", $$v)
                                  },
                                  expression: "formData.modifyName"
                                }
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "el-button el-button--primary",
                                  staticStyle: {
                                    padding: "10px 10px",
                                    margin: "0px 10px"
                                  },
                                  attrs: { type: "submit" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleModifyName()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("user.modifyName")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.headimgurl") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.headimgurl,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "headimgurl", $$v)
                                  },
                                  expression: "playerInfo.headimgurl"
                                }
                              }),
                              _c("el-input", {
                                staticClass: "new-input",
                                attrs: {
                                  type: "text",
                                  controls: false,
                                  placeholder: _vm.$t(
                                    "user.inputModifyHeadimgurl"
                                  )
                                },
                                model: {
                                  value: _vm.formData.modifyHeadimgurl,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "modifyHeadimgurl",
                                      $$v
                                    )
                                  },
                                  expression: "formData.modifyHeadimgurl"
                                }
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "el-button el-button--primary",
                                  staticStyle: {
                                    padding: "10px 10px",
                                    margin: "0px 10px"
                                  },
                                  attrs: { type: "submit" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleModifyHeadimgurl()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("user.modifyHeadimgurl")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("user.modifyNameCount") }
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.modifyName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "modifyName", $$v)
                                  },
                                  expression: "playerInfo.modifyName"
                                }
                              }),
                              _c("el-input-number", {
                                staticClass: "new-input",
                                attrs: {
                                  type: "number",
                                  controls: false,
                                  placeholder: _vm.$t(
                                    "user.inputModifyNameCount"
                                  )
                                },
                                model: {
                                  value: _vm.formData.modifyCount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "modifyCount", $$v)
                                  },
                                  expression: "formData.modifyCount"
                                }
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "el-button el-button--primary",
                                  staticStyle: {
                                    padding: "10px 10px",
                                    margin: "0px 10px"
                                  },
                                  attrs: { type: "submit" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleModifyCount(2)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("user.modifyCount")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.totalCount") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.totalCount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "totalCount", $$v)
                                  },
                                  expression: "playerInfo.totalCount"
                                }
                              }),
                              _c("el-input-number", {
                                staticClass: "new-input",
                                attrs: {
                                  type: "number",
                                  controls: false,
                                  placeholder: _vm.$t("user.inputTotalCount")
                                },
                                model: {
                                  value: _vm.formData.totalCount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "totalCount", $$v)
                                  },
                                  expression: "formData.totalCount"
                                }
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "el-button el-button--primary",
                                  staticStyle: {
                                    padding: "10px 10px",
                                    margin: "0px 10px"
                                  },
                                  attrs: { type: "submit" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleModifyCount(4)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("user.modifyTotalCount")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.from") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.from,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "from", $$v)
                                  },
                                  expression: "playerInfo.from"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.bindPhoneNumber
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.bindPhoneList") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.bindPhoneNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.playerInfo,
                                      "bindPhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "playerInfo.bindPhoneNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo &&
                      _vm.playerInfo.uid &&
                      _vm.playerInfo.from === "mobile"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.phoneName") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.phoneName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "phoneName", $$v)
                                  },
                                  expression: "playerInfo.phoneName"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo &&
                      _vm.playerInfo.uid &&
                      _vm.playerInfo.from === "mobile"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.mobile") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.mobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "mobile", $$v)
                                  },
                                  expression: "playerInfo.mobile"
                                }
                              }),
                              _c("el-input", {
                                staticClass: "new-input",
                                attrs: {
                                  controls: false,
                                  placeholder: _vm.$t("user.inputSmsCode"),
                                  onkeyup: "value=value.replace(/[^\\d]/g,'')"
                                },
                                model: {
                                  value: _vm.formData.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "code", $$v)
                                  },
                                  expression: "formData.code"
                                }
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "el-button el-button--primary",
                                  staticStyle: {
                                    padding: "10px 10px",
                                    margin: "0px 10px"
                                  },
                                  attrs: { type: "submit" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleSmsCode()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("user.fixSmsCode")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.coin") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.coin,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "coin", $$v)
                                  },
                                  expression: "playerInfo.coin"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "100", "99"],
                                      expression: "['admin', '100', '99']"
                                    }
                                  ]
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { type: "number", controls: false },
                                    model: {
                                      value: _vm.formData.coinCount,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "coinCount", $$v)
                                      },
                                      expression: "formData.coinCount"
                                    }
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "el-button el-button--primary",
                                      staticStyle: {
                                        padding: "10px 10px",
                                        margin: "0px 10px"
                                      },
                                      attrs: { type: "submit" },
                                      on: {
                                        click: function($event) {
                                          return _vm.chargeSubmit("2")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("user.chargeCoin")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.diamond") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.money,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "money", $$v)
                                  },
                                  expression: "playerInfo.money"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "100", "99"],
                                      expression: "['admin', '100', '99']"
                                    }
                                  ]
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { type: "number", controls: false },
                                    model: {
                                      value: _vm.formData.diamondCount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "diamondCount",
                                          $$v
                                        )
                                      },
                                      expression: "formData.diamondCount"
                                    }
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "el-button el-button--primary",
                                      staticStyle: {
                                        padding: "10px 10px",
                                        margin: "0px 10px"
                                      },
                                      attrs: { type: "submit" },
                                      on: {
                                        click: function($event) {
                                          return _vm.chargeSubmit("1")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("user.chargeDiamond")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.device") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.device,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "device", $$v)
                                  },
                                  expression: "playerInfo.device"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.loginToken") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.logintoken,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "logintoken", $$v)
                                  },
                                  expression: "playerInfo.logintoken"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.session
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.session") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.session,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "session", $$v)
                                  },
                                  expression: "playerInfo.session"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-button",
                            {
                              staticClass: "postInfo-container-item",
                              staticStyle: { "margin-left": "120px" },
                              attrs: {
                                "label-width": "120px",
                                type: "primary"
                              },
                              nativeOn: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.changeSex()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("user.changeSex")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.sex") } },
                            [
                              _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.playerInfo.sex,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "sex", $$v)
                                  },
                                  expression: "playerInfo.sex"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.appid") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.appid,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "appid", $$v)
                                  },
                                  expression: "playerInfo.appid"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.channel") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.channel,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "channel", $$v)
                                  },
                                  expression: "playerInfo.channel"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.publisher") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.publisher,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "publisher", $$v)
                                  },
                                  expression: "playerInfo.publisher"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.createTime") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.createat,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "createat", $$v)
                                  },
                                  expression: "playerInfo.createat"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.loginTime") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.loginTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "loginTime", $$v)
                                  },
                                  expression: "playerInfo.loginTime"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("user.logoutTime") } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.logoutTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "logoutTime", $$v)
                                  },
                                  expression: "playerInfo.logoutTime"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo &&
                      _vm.playerInfo.uid &&
                      _vm.checkoutDeadline(_vm.playerInfo.forbidChat)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("user.forbidChatDeadline")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.forbidChat,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "forbidChat", $$v)
                                  },
                                  expression: "playerInfo.forbidChat"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo &&
                      _vm.playerInfo.uid &&
                      _vm.checkoutDeadline(_vm.playerInfo.forbidGame)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("user.forbidGameDeadline")
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: true, type: "text" },
                                model: {
                                  value: _vm.playerInfo.forbidGame,
                                  callback: function($$v) {
                                    _vm.$set(_vm.playerInfo, "forbidGame", $$v)
                                  },
                                  expression: "playerInfo.forbidGame"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "div",
                            { staticClass: "postInfo-container" },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass:
                                            "postInfo-container-item",
                                          attrs: {
                                            "label-width": "120px",
                                            label: _vm.$t("user.reports")
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              readonly: true,
                                              type: "text"
                                            },
                                            model: {
                                              value: _vm.playerInfo.reports,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.playerInfo,
                                                  "reports",
                                                  $$v
                                                )
                                              },
                                              expression: "playerInfo.reports"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass:
                                            "postInfo-container-item",
                                          attrs: {
                                            "label-width": "120px",
                                            label: _vm.$t("user.forbidden")
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "user.forbidden"
                                                ),
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.reportInfo.type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.reportInfo,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "reportInfo.type"
                                              }
                                            },
                                            _vm._l(_vm.radioButtion2, function(
                                              item,
                                              key
                                            ) {
                                              return _c("el-option", {
                                                key: key,
                                                attrs: {
                                                  label: _vm.$t(item.dec),
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass:
                                            "postInfo-container-item",
                                          attrs: {
                                            "label-width": "120px",
                                            label: _vm.$t("operation.timeType")
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "operation.timeType"
                                                ),
                                                clearable: ""
                                              },
                                              model: {
                                                value: _vm.reportInfo.timeType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.reportInfo,
                                                    "timeType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "reportInfo.timeType"
                                              }
                                            },
                                            _vm._l(_vm.timeTypes, function(
                                              item,
                                              key
                                            ) {
                                              return _c("el-option", {
                                                key: key,
                                                attrs: {
                                                  label: _vm.$t(item.dec),
                                                  value: item.value
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _vm.reportInfo.timeType === 1
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              attrs: {
                                                "label-width": "120px",
                                                label: _vm.$t(
                                                  "operation.period"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 16 } },
                                                [
                                                  _c("el-input", {
                                                    attrs: { type: "text" },
                                                    model: {
                                                      value:
                                                        _vm.reportInfo.hours,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.reportInfo,
                                                          "hours",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "reportInfo.hours"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 4 } },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.reportHours"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              attrs: {
                                                "label-width": "120px",
                                                label: _vm.$t(
                                                  "operation.deadline"
                                                )
                                              }
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticClass: "filter-item",
                                                staticStyle: { width: "300px" },
                                                attrs: {
                                                  type: "datetime",
                                                  placeholder: _vm.$t(
                                                    "operation.selectDatetime"
                                                  ),
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm",
                                                  format: "yyyy-MM-dd HH:mm",
                                                  align: "right",
                                                  "unlink-panels": ""
                                                },
                                                on: { change: _vm.dataSearch },
                                                model: {
                                                  value:
                                                    _vm.reportInfo.datetime,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.reportInfo,
                                                      "datetime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "reportInfo.datetime"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "title",
                                            label: _vm.$t("gameSetting.title")
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              placeholder: _vm.$t(
                                                "gameSetting.inputTitle"
                                              )
                                            },
                                            model: {
                                              value: _vm.reportInfo.title,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.reportInfo,
                                                  "title",
                                                  $$v
                                                )
                                              },
                                              expression: "reportInfo.title"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "mailContent",
                                            label: _vm.$t(
                                              "gameSetting.mailContent"
                                            )
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              placeholder: _vm.$t(
                                                "gameSetting.inputMailContent"
                                              )
                                            },
                                            model: {
                                              value: _vm.reportInfo.mailContent,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.reportInfo,
                                                  "mailContent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "reportInfo.mailContent"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _vm.playerInfo && _vm.playerInfo.uid
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              staticStyle: {
                                                "margin-left": "120px"
                                              },
                                              attrs: {
                                                "label-width": "120px",
                                                type: "primary"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.unblockButton(1)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.$t("user.ban")) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.playerInfo && _vm.playerInfo.uid
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              staticStyle: {
                                                "margin-left": "120px"
                                              },
                                              attrs: {
                                                "label-width": "120px",
                                                type: "primary"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.unblockButton(3)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("user.banDevice")
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.playerInfo && _vm.playerInfo.uid
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              staticStyle: {
                                                "margin-left": "120px"
                                              },
                                              attrs: {
                                                "label-width": "120px",
                                                type: "primary"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.unblockButton(2)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("user.unblock")
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _vm.playerInfo &&
                                      _vm.playerInfo.uid &&
                                      _vm.playerInfo.vip > 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("user.vipLevel")
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  readonly: true,
                                                  type: "text"
                                                },
                                                model: {
                                                  value: _vm.playerInfo.vip,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.playerInfo,
                                                      "vip",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "playerInfo.vip"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.playerInfo &&
                                      _vm.playerInfo.uid &&
                                      _vm.playerInfo.vip > 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("user.vipEndTime")
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  readonly: true,
                                                  type: "text"
                                                },
                                                model: {
                                                  value:
                                                    _vm.playerInfo.vipEndTime,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.playerInfo,
                                                      "vipEndTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "playerInfo.vipEndTime"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["admin", "100", "99"],
                                              expression:
                                                "['admin', '100', '99']"
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "postInfo-container-item",
                                              attrs: {
                                                "label-width": "120px",
                                                label: _vm.$t("user.vipType")
                                              }
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass:
                                                    "postInfo-container-item",
                                                  attrs: {
                                                    placeholder: _vm.$t(
                                                      "user.vipType"
                                                    ),
                                                    clearable: ""
                                                  },
                                                  model: {
                                                    value: _vm.vipType,
                                                    callback: function($$v) {
                                                      _vm.vipType = $$v
                                                    },
                                                    expression: "vipType"
                                                  }
                                                },
                                                _vm._l(_vm.vipTypes, function(
                                                  item,
                                                  key
                                                ) {
                                                  return _c("el-option", {
                                                    key: key,
                                                    attrs: {
                                                      label: _vm.$t(item.dec),
                                                      value: item.value
                                                    }
                                                  })
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.playerInfo && _vm.playerInfo.uid
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "postInfo-container-item",
                                                  staticStyle: {
                                                    "margin-left": "120px"
                                                  },
                                                  attrs: {
                                                    "label-width": "120px",
                                                    type: "primary"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.chargeVip()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("user.vipCharge")
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _vm.playerInfo && _vm.playerInfo.uid
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "user.vipExpValue"
                                                )
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  readonly: true,
                                                  type: "text"
                                                },
                                                model: {
                                                  value:
                                                    _vm.playerInfo.vipExpValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.playerInfo,
                                                      "vipExpValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "playerInfo.vipExpValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["admin", "100", "99"],
                                              expression:
                                                "['admin', '100', '99']"
                                            }
                                          ]
                                        },
                                        [
                                          _vm.playerInfo && _vm.playerInfo.uid
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "postInfo-container-item",
                                                  attrs: {
                                                    label: _vm.$t("user.vipExp")
                                                  }
                                                },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      type: "number",
                                                      max: 9000000,
                                                      step: 10,
                                                      value: "0"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.playerInfo.vipExp,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.playerInfo,
                                                          "vipExp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "playerInfo.vipExp"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.playerInfo && _vm.playerInfo.uid
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "postInfo-container-item",
                                                  staticStyle: {
                                                    "margin-left": "120px"
                                                  },
                                                  attrs: {
                                                    "label-width": "120px",
                                                    type: "primary"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.addVipExp()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("user.addVipExp")
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _vm.playerInfo && _vm.playerInfo.uid
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("user.level")
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  readonly: true,
                                                  type: "text"
                                                },
                                                model: {
                                                  value: _vm.playerInfo.level,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.playerInfo,
                                                      "level",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "playerInfo.level"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.playerInfo && _vm.playerInfo.uid
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t("user.exp")
                                              }
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  readonly: true,
                                                  type: "text"
                                                },
                                                model: {
                                                  value:
                                                    _vm.playerInfo.expValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.playerInfo,
                                                      "expValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "playerInfo.expValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["admin", "100", "99"],
                                              expression:
                                                "['admin', '100', '99']"
                                            }
                                          ]
                                        },
                                        [
                                          _vm.playerInfo && _vm.playerInfo.uid
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "postInfo-container-item",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "user.inputExp"
                                                    )
                                                  }
                                                },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      type: "number",
                                                      max: 9000000,
                                                      step: 10,
                                                      value: "0"
                                                    },
                                                    model: {
                                                      value: _vm.playerInfo.exp,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.playerInfo,
                                                          "exp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "playerInfo.exp"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.playerInfo && _vm.playerInfo.uid
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticClass:
                                                    "postInfo-container-item",
                                                  staticStyle: {
                                                    "margin-left": "120px"
                                                  },
                                                  attrs: {
                                                    "label-width": "120px",
                                                    type: "primary"
                                                  },
                                                  nativeOn: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.addExp()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("user.addExp")
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.playerInfo && _vm.playerInfo.uid
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              nativeOn: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.reset($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("user.reset")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }